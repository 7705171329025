<template>
  <b-modal id="modal-events" hide-footer>
    <template #modal-header>
      <h4>
        {{ data.id === null ? $t("events.new") : $t("events.edit") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row class="justify-content-md-center">
            <b-col md="12">
              <label>{{ $t("nursing.image") }} </label>
              <div class="text-center mb-4" style="cursor: pointer">
                <b-img
                  class="mt-2"
                  id="pick-image-event"
                  style="max-width: 300px"
                  :src="
                    preview
                      ? preview
                      : require('@/assets/images/logo/placeholder.png')
                  "
                ></b-img>

                <avatar-cropper
                  :upload-handler="selectedFile"
                  @changed="setFileName"
                  trigger="#pick-image-event"
                  :labels="{
                    submit: $t('app.accept'),
                    cancel: $t('app.cancel'),
                  }"
                  :cropper-options="cropperOptions"
                />
              </div>
            </b-col>
            <!-- NAME -->
            <b-form-group
              class="col-md-12"
              :label="$t('events.name')"
              label-for="name"
            >
              <ValidationProvider
                :name="$t('events.name')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="data.name"
                  type="text"
                  :placeholder="$t('events.name-placeholder')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <!-- DESCRIPTION -->
            <b-form-group
              class="col-md-12"
              :label="$t('events.description')"
              label-for="acronym"
            >
              <ValidationProvider
                :name="$t('events.description')"
                rules=""
                v-slot="{ errors }"
              >
                <b-form-textarea
                  v-model="data.description"
                  :placeholder="$t('events.description-placeholder')"
                >
                </b-form-textarea>
              </ValidationProvider>
            </b-form-group>
            <!-- DESCRIPTION -->
            <b-form-group
              class="col-md-12"
              :label="$t('events.address')"
              label-for="acronym"
            >
              <ValidationProvider
                :name="$t('events.address')"
                rules=""
                v-slot="{ errors }"
              >
                <b-form-textarea
                  v-model="data.location"
                  :placeholder="$t('events.address')"
                >
                </b-form-textarea>
              </ValidationProvider>
            </b-form-group>
            <b-form-group
              class="col-md-12"
              :label="$t('events.initial_date')"
              label-for="acronym"
            >
              <ValidationProvider
                :name="$t('events.initial_date')"
                rules="required"
                v-slot="{ errors }"
              >
                <date-picker
                  v-model="data.initial_date"
                  :lang="'es'"
                  type="datetime"
                  format="DD-MM-YYYY hh:mm a"
                  value-type="YYYY-MM-DD HH:mm"
                  :input-class="`form-control ${
                    errors.length > 0 ? ' is-invalid' : ''
                  }`"
                  style="width: 100% !important"
                  :placeholder="$t('events.initial_date-placeholder')"
                ></date-picker>
                <div class="text-danger">
                  <small>{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group
              class="col-md-12"
              :label="$t('events.final_date')"
              label-for="acronym"
            >
              <ValidationProvider
                :name="$t('events.final_date')"
                rules="required"
                v-slot="{ errors }"
              >
                <date-picker
                  v-model="data.final_date"
                  :lang="'es'"
                  type="datetime"
                  format="DD-MM-YYYY hh:mm a"
                  value-type="YYYY-MM-DD HH:mm"
                  :input-class="`form-control ${
                    errors.length > 0 ? ' is-invalid' : ''
                  }`"
                  style="width: 100% !important"
                  :placeholder="$t('events.final_date-placeholder')"
                ></date-picker>
                <div class="text-danger">
                  <small>{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <hr />
          <b-button
            class="float-right"
            :disabled="eventsLoading"
            variant="success"
            type="submit"
          >
            <span v-if="eventsLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            @click="$bvModal.hide('modal-events')"
            variant="outline-danger"
            type="reset"
          >
            {{ $t("app.cancel") }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import AvatarCropper from "vue-avatar-cropper";

export default {
  name: "modalForm",
  components: { DatePicker, AvatarCropper },
  mounted() {
    core.index();
  },
  data() {
    return {
      fileSelected: null,
      preview: "",
      data: {
        id: null,
        name: null,
        description: null,
        initial_date: null,
        initial_time: null,
        final_date: null,
        final_time: null,
        location: null,
        image: null,
      },
      cropperOptions: {
        aspectRatio: 0,
        autoCropArea: 1,
        viewMode: 1,
        movable: true,
        zoomable: true,
      },
    };
  },
  methods: {
    ...mapActions({
      saveForm: "storeEvents",
    }),
    async show(item) {
      this.reserForm();
      if (item) {
        this.preview = item.image;
        this.data = {
          id: item.id,
          name: item.name,
          description: item.description,
          location: item.location,
          initial_date: item.initial_date,
          initial_time: item.initial_time,
          final_date: item.final_date,
          final_time: item.final_time,
        };
      }
      this.$bvModal.show("modal-events");
    },
    setFileName(file) {
      this.fileSelected = file;
      this.data.image = file;
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = core.dataURLtoFile(dataUrl, this.fileSelected.name);
      this.preview = dataUrl;
      this.data.image = file;
    },
    async onSubmit() {
      let payload = {
        ...this.data,
        initial_date: moment(this.data.initial_date).format("YYYY-MM-DD"),
        initial_time: moment(this.data.initial_date, "YYYY-MM-DD HH:mm").format(
          "HH:mm"
        ),
        final_date: moment(this.data.final_date).format("YYYY-MM-DD"),
        final_time: moment(this.data.final_date, "YYYY-MM-DD HH:mm").format(
          "HH:mm"
        ),
      };
      const resp = await this.saveForm(payload);
      this.$bvModal.hide("modal-events");
      this.$emit("refresh");
      if (resp.status === 201 || resp.status === 200) {
        core.showSnackbar(
          "success",
          !this.data.id
            ? this.$t("app.success-create")
            : this.$t("app.success-edit")
        );
      }
      this.reserForm();
    },
    reserForm() {
      this.preview = null;
      this.fileSelected = null;
      this.data = {
        id: null,
        name: null,
        description: null,
        location: null,
        initial_date: null,
        initial_time: null,
        final_date: null,
        final_time: null,
        image: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      eventsLoading: "eventsLoading",
    }),
  },
};
</script>
