<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { mapGetters } from "vuex";
import esLocale from "@fullcalendar/core/locales/es";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  data: function () {
    return {
      week: true,
      currentEvents: [],
      dataShow: {},
    };
  },

  methods: {
    handleWeekendsToggle() {
      this.week = !this.week; // update a property
    },

    handleDateSelect(data) {
      // console.log(data);
      // this.$refs.modalGrades.show();
    },

    handleEventClick(data) {
      // console.log(data.event);
      this.dataShow = data.event;
      this.$bvModal.show("modal-1");
      // this.$refs.modalGrades.show();
    },

    handleEvents(events) {
      this.currentEvents = events;
    },
  },
  computed: {
    ...mapGetters({
      getEventsCalendar: "getEventsCalendar",
    }),
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        locale: this.$i18n.locale == "es" ? esLocale : "",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        events: this.getEventsCalendar,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: this.week,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
        },
      };
    },
  },
};
</script>

<template>
  <div class="demo-app">
    <div class="demo-app-main">
      <FullCalendar
        ref="calendar"
        class="demo-app-calendar"
        :options="calendarOptions"
      >
        <template v-slot:eventContent="arg">
          <b><i class="ri-calendar-event-line ml-1"></i> {{ arg.timeText }}</b>
          <i> {{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </div>
    <b-modal
      id="modal-1"
      size="lg"
      :title="$t('events.details')"
      hide-footer
      centered
    >
      <b-row v-if="dataShow.extendedProps">
        <b-col :md="4">
          <strong class="h5">
            <i class="ri-award-line"></i>
            {{ $t("events.name") }}
          </strong>
        </b-col>
        <b-col :md="7">
          <p class="h5">
            {{ dataShow.extendedProps.eventData.title }}
          </p>
        </b-col>

        <b-col :md="4">
          <strong class="h5">
            <i class="ri-calendar-event-line"></i>
            {{ $t("events.initial_date") }}
          </strong>
        </b-col>
        <b-col :md="7">
          <p class="h5">
            {{ dataShow.extendedProps.eventData.start }}
          </p>
        </b-col>

        <b-col :md="4">
          <strong class="h5">
            <i class="ri-calendar-event-line"></i>
            {{ $t("events.final_date") }}
          </strong>
        </b-col>
        <b-col :md="7">
          <p class="h5">
            {{ dataShow.extendedProps.eventData.end }}
          </p>
        </b-col>

        <b-col :md="4">
          <strong class="h5">
            <i class="ri-todo-line"></i>
            {{ $t("events.description") }}
          </strong>
        </b-col>
        <b-col :md="7">
          <p class="h5">
            {{ dataShow.extendedProps.eventData.description }}
          </p>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<style lang="css">
.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}
</style>
