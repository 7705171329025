<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("sidebar.events") }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              v-if="collegeGoogleCalendars.length"
              variant="success"
              class="mr-2"
              :disabled="eventsLoading"
              @click="syncGoogleCalendarEvents"
              >{{
                eventsLoading
                  ? $t("app.sync")
                  : $t("colleges.google-calendar.sync")
              }}</b-button
            >
            <b-button variant="primary" @click="$refs.modalEvents.show()">{{
              $t("app.new")
            }}</b-button>
            <b-button
              class="ml-2"
              @click="changeTypeViewAndGetEvents"
              variant="secondary"
              >{{ $t(typeView ? "calendar.name" : "calendar.list") }}</b-button
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12" md="1" class="pr-1 mb-2" v-if="typeView">
                <b-form-select
                  :options="['5', '10', '20', '50', '100']"
                  v-model="filter.per_page"
                  size="sm"
                  @input="getData()"
                ></b-form-select>
              </b-col>
              <b-col lg="3" md="6" sm="12" class="ml-auto mb-2" v-if="typeView">
                <b-form-group>
                  <b-form-input
                    style="height: 35px"
                    :disabled="
                      !Array.isArray(getEvents.data) ||
                      (getEvents.length === 0 && eventsLoading === false)
                    "
                    v-model="filter.search"
                    type="search"
                    :placeholder="$t('app.search')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12" class="table-responsive">
                <EventCalendar v-if="!typeView" />
                <b-table
                  v-if="typeView"
                  class="table mb-0 table-borderless"
                  :items="getEvents.data"
                  :fields="columns"
                  :per-page="filter.per_page"
                  :busy="
                    !Array.isArray(getEvents.data) || getEvents.length === 0
                      ? true
                      : false || eventsLoading == true
                  "
                  @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="eventsLoading">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong>{{ $t("app.loading") }}...</strong>
                      </div>
                      <span class="h5" v-else>{{ $t("app.not-found") }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(initial_date)="data">
                    {{
                      moment(data.item.initial_date).format("DD-MM-YYYY HH:mm")
                    }}
                    hrs.
                  </template>
                  <template v-slot:cell(final_date)="data">
                    {{
                      moment(data.item.final_date).format("DD-MM-YYYY HH:mm")
                    }}
                    hrs.
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      variant=" iq-bg-warning rounded"
                      class="mr-1"
                      size="sm"
                      @click="$refs.modalEvents.show(data.item)"
                      ><i class="ri-ball-pen-fill ml-1"></i
                    ></b-button>
                    <b-button
                      @click="deleteItem(data.item.id)"
                      variant=" iq-bg-danger rounded"
                      size="sm"
                      ><i class="ri-delete-bin-line ml-1"></i
                    ></b-button>
                  </template>
                </b-table>
                <b-pagination
                  v-if="typeView"
                  class="mt-3 float-right"
                  v-model="filter.page"
                  :total-rows="getEvents.total"
                  :per-page="getEvents.per_page"
                  first-number
                  pills
                  size="sm"
                  @change="getData"
                ></b-pagination>
                <p class="mt-3">
                  {{ $t("app.registers") }} {{ getEvents.to }}
                  {{ $t("app.of") }} {{ getEvents.total }}. Total
                  {{ getEvents.total }}
                </p>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modal-form ref="modalEvents" @refresh="getData()" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalForm from "./Components/CreatedOrUpdate.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import EventCalendar from "./Components/calendar.vue";
import moment from "moment";
export default {
  name: "Courses",
  components: {
    modalForm,
    EventCalendar,
  },
  mounted() {
    core.index();
    this.fetchCollegeGoogleCalendars();
    this.debouncedGetAnswer = _.debounce(this.getData, 500);
    this.getData();
  },
  watch: {
    "filter.search": {
      handler() {
        this.debouncedGetAnswer();
      },
    },
  },
  methods: {
    ...mapActions({
      syncAllCollegeGoogleCalendarEvents: "syncAllCollegeGoogleCalendarEvents",
      fetchCollegeGoogleCalendars: "fetchCollegeGoogleCalendars",
      fetchEventsColleges: "fetchEventsColleges",
      deleteEvents: "deleteEvents",
    }),
    async syncGoogleCalendarEvents() {
      const resp = await this.syncAllCollegeGoogleCalendarEvents();
      if (resp.status === 200) {
        this.getData();
        core.showSnackbar("success", resp.data.message);
      }
    },
    async changeTypeViewAndGetEvents() {
      this.typeView = !this.typeView;
      await this.getData();
    },
    async getData(page = 1) {
      this.filter.page = page;
      await this.fetchEventsColleges({
        ...this.filter,
        paginate: !this.typeView ? undefined : true,
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteEvents(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  data() {
    return {
      filter: {
        paginate: true,
        page: 1,
        per_page: 10,
        search: null,
      },
      totalRows: 1,
      typeView: false,
      moment,
    };
  },
  computed: {
    ...mapGetters({
      getEvents: "getEvents",
      collegeGoogleCalendars: "collegeGoogleCalendars",
      eventsLoading: "eventsLoading",
    }),
    columns() {
      return [
        { label: this.$t("events.name"), key: "name", class: "text-center" },
        {
          label: this.$t("events.initial_date"),
          key: "initial_date",
          class: "text-center",
        },
        {
          label: this.$t("events.final_date"),
          key: "final_date",
          class: "text-center",
        },
        {
          label: this.$t("events.description"),
          key: "description",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
